<template>
    <div class="container-fluid">
        <div class="d-flex justify-content-center align-content-center flex-wrap">
            <router-view/>
        </div>
    </div>
</template>

<script>

export default {
    name: "App",
}
</script>
