<template>
    <div class="text-center">
        <div class="d-flex justify-content-center mt-sm-1 mt-md-3">
            <img alt="Handover" src="@/assets/logo.svg">
        </div>
        <h1>Welcome to Handover</h1>
        <p>
            You need to contact <a href="https://rh-dev.io/" target="_blank">Support</a> to get a working link.
        </p>
    </div>
</template>

<script>
export default {
    name: 'MissingComponent',
}
</script>

<style scoped>
img {
    max-width: 200px;
    height: auto;
}
</style>
