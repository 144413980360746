<template>
    <div>
        <MissingComponent />
    </div>
</template>

<script>
import MissingComponent from '@/components/MissingComponent.vue'

export default {
    name: 'GetMissingUUIDView',
    components: {
        MissingComponent
    }
}
</script>
