import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

// Sentry
import * as Sentry from '@sentry/vue'
import { BrowserTracing } from '@sentry/tracing'

Sentry.init({
    App,
    dsn: "https://1c3631f8923e4b208e9cc9bf811e2dfb@sentry.rh-dev.io/35",
    integrations: [
        new BrowserTracing({
            routingInstrumentation: Sentry.vueRouterInstrumentation(router),
            tracingOrigins: ['localhost', 'handover.rh-dev.io', /^\//],
        }),
    ],
    tracesSampleRate: 1.0,
    trackComponents: true,
    hooks: ['activate', 'create', 'destroy', 'mount', 'unmount', 'update'],
    environment: 'production'
})

// Imports
import 'bootstrap'
import 'bootstrap/dist/css/bootstrap.min.css'

import VueSweetalert2 from 'vue-sweetalert2';
import 'sweetalert2/dist/sweetalert2.min.css'

createApp(App)
    .use(store)
    .use(router)
    .use(VueSweetalert2)
    .mount('#app')
