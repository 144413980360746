import { createStore } from 'vuex'

export default createStore({
  state: {
    baseUrl: 'https://cp.rh-dev.io/api/handover/',
  },
  getters: {
    getBaseUrl: state => {
      return state.baseUrl;
    },
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
