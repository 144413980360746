<template>
    <div class="text-center">
        <div class="d-flex justify-content-center mt-sm-1 mt-md-3">
            <img alt="Handover" src="@/assets/logo.svg">
        </div>
        <h1>
            Your data has been submitted, Thank You!
        </h1>
    </div>
</template>

<script>
export default {
    name: 'SubmittedComponent',
}
</script>

<style scoped>
img {
    max-width: 200px;
    height: auto;
}
</style>
