<template>
    <div class="home">
        <MissingComponent msg="Welcome to Handover"/>
    </div>
</template>

<script>
import MissingComponent from '@/components/MissingComponent.vue'

export default {
    name: 'HomeView',
    components: {
        MissingComponent
    }
}
</script>
