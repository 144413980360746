<template>
    <div class="row mt-3">
        <div class="col-sm-12 text-center">
            <h1>Your Handover</h1>
        </div>
        <div class="col-sm-12 mt-2">
            <textarea :value="handover" class="form-control" rows="10" readonly/>
        </div>
    </div>
</template>

<script>
export default {
    name: "GetComponent",
    props: {
        handover: String
    }
}
</script>

<style scoped>
textarea {
    resize: none;
}
</style>