import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '@/views/HomeView'
import GetView from '@/views/GetView'
import AddView from '@/views/AddView'
import GetMissingUUIDView from '@/views/Missing/GetMissingUUIDView'
import AddMissingUUIDView from '@/views/Missing/AddMissingUUIDView'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta: {
      title: ''
    }
  },
  {
    path: '/get/:uuid',
    name: 'get',
    component: GetView,
    meta: {
      title: 'Get'
    }
  },
  {
    path: '/get',
    name: 'getMissing',
    component: GetMissingUUIDView,
    meta: {
      title: 'Get'
    }
  },
  {
    path: '/add/:uuid',
    name: 'add',
    component: AddView,
    meta: {
      title: 'Add'
    }
  },
  {
    path: '/add',
    name: 'addMissing',
    component: AddMissingUUIDView,
    meta: {
      title: 'Add'
    }
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);
  const previousNearestWithMeta = from.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  if(nearestWithTitle) {
    document.title = nearestWithTitle.meta.title + " | Handover" || "Handover";
  } else if(previousNearestWithMeta) {
    document.title = previousNearestWithMeta.meta.title + " | Handover" || "Handover";
  }

  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));
  if(!nearestWithMeta) return next();

  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');
    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    tag.setAttribute('data-vue-router-controlled', '');
    return tag;
  }).forEach(tag => document.head.appendChild(tag));
})

export default router
