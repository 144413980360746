<template>
    <div v-if="!isFetching">
        <div v-if="missing">
            <MissingComponent />
        </div>
        <div v-else-if="mfaRequired">

            <div class="row mt-3">
                <div class="col-sm-12 text-center">
                    <h1>MFA Required</h1>
                </div>
                <div class="col-sm-12 mt-2">
                    <input type="text" id="mfaCode" placeholder="ENTER YOUR MFA CODE" class="form-control text-center">
                </div>
                <div class="col-sm-12 col-md-6 d-grid mt-2">
                    <button type="button" v-on:click="getMFACode()" class="btn btn-primary">Request MFA Code</button>
                </div>
                <div class="col-sm-12 col-md-6 d-grid mt-2">
                    <button type="submit" v-on:click="submitMFA()" class="btn btn-success">Submit MFA Code</button>
                </div>
            </div>

        </div>
        <div v-else>
            <GetComponent :handover="handover" />
        </div>
    </div>
</template>

<script>
import store from '@/store/index';
import axios from 'axios';

import MissingComponent from '@/components/MissingComponent';
import GetComponent from '@/components/GetComponent';

export default {
    name: 'GetView',
    components: {
        MissingComponent,
        GetComponent
    },
    data() {
        return {
            isFetching: true,
            missing: true,
            mfaRequired: true,
            handover: null
        }
    },
    mounted() {
        this.getMFAStatus()
    },
    methods: {
        getMFAStatus: function() {
            let url = store.getters.getBaseUrl + 'check-mfa/' + this.$route.params.uuid + '/';
            axios
                .get(url)
                .then(response => {
                    if (response.data.status) {
                        this.mfaRequired = response.data.mfa_enabled;
                        if (!response.data.mfa_enabled) {
                            this.getHandoverNoMFA()
                        } else {
                            this.mfaRequired = true;
                        }
                        this.missing = false;
                        this.isFetching = false;
                    }
                }).catch(() => {
                    this.isFetching = false;
            })
        },
        getHandoverNoMFA: function() {
            let url = store.getters.getBaseUrl + 'get/' + this.$route.params.uuid + '/';
            axios
                .get(url)
                .then(response => {
                    if (response.data.status) {
                        this.handover = response.data.payload;
                    }
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.missing = true;
                    }
                })
        },
        submitMFA: function() {
            let mfaCode = document.getElementById("mfaCode").value;
            if (mfaCode) {
                let headers = {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'X-MFA-CODE': mfaCode,
                }

                let url = store.getters.getBaseUrl + 'get/' + this.$route.params.uuid + '/';
                axios
                    .get(url, { headers: headers })
                    .then(response => {
                        if (response.data.status) {
                            this.handover = response.data.payload;
                            this.mfaRequired = false;
                            this.missing = false;
                            this.isFetching = false;
                        }
                    }).catch((error) => {
                        if (error.response.status === 403) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Wrong MFA Code',
                                timer: 5000,
                                timerProgressBar: true,
                                position: 'center',
                                toast: true,
                                showCloseButton: false,
                                showCancelButton: false,
                                showDenyButton: false,
                                showConfirmButton: false,
                            })
                        }
                    })
            }
        },
        getMFACode: function() {
            this.$swal.fire({
                icon: 'info',
                title: 'Get MFA Code ?',
                showConfirmButton: true,
                confirmButtonText: 'Yes',
                confirmButtonColor: 'green',
                showCancelButton: true,
                cancelButtonText: 'Not Yet',
                cancelButtonColor: 'red'
            }).then((result) => {
                if (result.isConfirmed) {
                    let url = store.getters.getBaseUrl + 'request-mfa/' + this.$route.params.uuid + '/';
                    axios
                        .get(url)
                        .then(() => {
                            this.$swal.fire({
                                icon: 'success',
                                title: 'Your MFA Code has been sent',
                                timer: 2000,
                                timerProgressBar: true,
                                position: 'center',
                                toast: true,
                                showCloseButton: false,
                                showCancelButton: false,
                                showDenyButton: false,
                                showConfirmButton: false,
                            })
                        }).catch((error) => {
                            if (error.response.status === 500) {
                                this.$swal.fire({
                                    icon: 'error',
                                    title: 'Something went wrong',
                                    text: 'Please contact support',
                                    showCloseButton: true,
                                    showCancelButton: false,
                                    showDenyButton: false,
                                    showConfirmButton: false,
                                })
                            }
                        if (error.response.status === 404) {
                            this.$swal.fire({
                                icon: 'error',
                                title: 'Handover was not found',
                                timer: 5000,
                                timerProgressBar: true,
                                position: 'center',
                                toast: true,
                                showCloseButton: false,
                                showCancelButton: false,
                                showDenyButton: false,
                                showConfirmButton: false,
                            })
                        }
                    })
                }
            })

        }
    }
}
</script>
