<template>
    <div v-if="!isFetching">
        <div v-if="missing">
            <MissingComponent />
        </div>
        <div v-else-if="!submitted" class="row mt-3">
            <div class="col-sm-12 text-center">
                <h1>Submit data to Handover</h1>
            </div>
            <div class="col-sm-12 mt-2">
                <textarea id="payload" class="form-control" rows="10" />
            </div>
            <div class="col-sm-12 col-md-6 d-grid mt-2">
                <button type="submit" v-on:click="submitHandover()" class="btn btn-success">Submit</button>
            </div>
        </div>
        <div v-else>
            <SubmittedComponent />
        </div>
    </div>
</template>

<script>
import store from '@/store/index';
import axios from 'axios';

import SubmittedComponent from '@/components/SubmittedComponent';
import MissingComponent from '@/components/MissingComponent';

export default {
    name: 'AddView',
    data() {
        return {
            isFetching: true,
            submitted: true,
            missing: false,
        }
    },
    components: {
        SubmittedComponent,
        MissingComponent
    },
    mounted() {
        this.getStatus()
    },
    methods: {
        getStatus: function() {
            let url = store.getters.getBaseUrl + 'add/' + this.$route.params.uuid + '/submitted/';
            axios
                .get(url)
                .then(response => {
                    if (response.data.status) {
                        this.submitted = false;
                        this.isFetching = false;
                    }
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.missing = true;
                    }
                this.isFetching = false;
            })
        },
        submitHandover: function() {
            let url = store.getters.getBaseUrl + 'add/' + this.$route.params.uuid + '/';
            let string = document.getElementById('payload').value;
            let headers = {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
            }

            axios
                .post(url, { string: string },{ headers: headers })
                .then(response => {
                    if (response.data.status) {
                        this.submitted = true;
                    }
                }).catch((error) => {
                    if (error.response.status === 404) {
                        this.$swal.fire({
                            icon: 'error',
                            title: 'Something went wrong',
                            text: 'Please contact support',
                            showCloseButton: true,
                            showCancelButton: false,
                            showDenyButton: false,
                            showConfirmButton: false,
                        })
                    }
            })
        }
    }
}
</script>

<style scoped>
textarea {
    resize: none;
}
</style>
